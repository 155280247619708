import React, { useEffect, useState, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { AuthLayout } from "../components/common/components/AuthLayout";
import Login from "../components/Login";
import { MainLayout } from "../components/common/components/MainLayout";
import { Missing } from "../components/Missing";
import { ILTModel } from "../components/ilts/models/ilt.model";
import { BusyIndicator } from "../components/common/components/BusyIndicator";

import UserProfile from "../components/users/user-profile";
import UserList from "../components/users/user-list";
import ILTList from "../components/ilts/ilt-list";
import { useLocation } from "react-router-dom";
// Lazy loaded components
// const ILTList = React.lazy(() => import("../components/ilts/ilt-list"));
// const UserList = React.lazy(() => import("../components/users/user-list"));
const ILTMeetings = React.lazy(() => import("../components/ilts/ilt-meetings"));
const ILTCalendar = React.lazy(() => import("../components/ilts/ilt-calendar"));
// const UserProfile = React.lazy(() => import("../components/users/user-profile"));
const Dashboard = React.lazy(() => import("../components/common/components/Dashboard"));
const AppRoutes = () => {
  const [selectedILT, setSelectedILT] = useState<ILTModel>({} as ILTModel);

  useEffect(() => {
    const storedSelectedILT = sessionStorage.getItem('selectedILT');
    if (selectedILT.iltId !== undefined && storedSelectedILT !== JSON.stringify(selectedILT)) {
      sessionStorage.setItem('selectedILT', JSON.stringify(selectedILT));
    }
  }, [selectedILT]);

  useEffect(() => {
    const storedSelectedILT = sessionStorage.getItem('selectedILT');
    if (storedSelectedILT) {
      setSelectedILT(JSON.parse(storedSelectedILT));
    }
  }, []);
  const location = useLocation();
    useEffect(() => {
      if(location.pathname !== '/login'){
        localStorage.setItem('path',location.pathname.slice(1))
      }
    }, [location]);
  return (
    <Suspense fallback={<BusyIndicator loading={true}></BusyIndicator>}>
      <Routes>
        <Route element={<AuthLayout />}>  {/*  need to design loading page */}
          <Route path="/" element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="*" element={<Missing />} />
        </Route>

        <Route element={<MainLayout />}>
          <Route
            path="dashboard"
            element={<Dashboard selectedILT={selectedILT} setSelectedILT={setSelectedILT} />}
          />
          <Route
            path="users"
            element={<UserList userRole="Administrator" />}
          />
          <Route
            path="profile"
            element={<UserProfile />}
          />
          <Route
            path="ilts"
            element={<ILTList />}
          />
          <Route
            path="meetings"
            element={<ILTMeetings selectedILT={selectedILT} setSelectedILT={setSelectedILT} />}
          />
          <Route
            path="calendar"
            element={<ILTCalendar selectedILT={selectedILT} setSelectedILT={setSelectedILT} />}
          />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;